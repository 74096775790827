@font-face {
  font-family: 'Montserrat';
  
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url("/assets/fonts/Montserrat-Light.ttf") format("truetype"),
    url("https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2") format('woff2');
  font-display: swap;
}

@media screen and (max-width: 600px) {
  :host { font-size: var(--mini-font-size); }
}