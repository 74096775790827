:root {
  hyphens: auto;

  // change the selection-color :)
  ::selection {
    background-color: var(--ion-color-tertiary-tint);
    color: var(--ion-color-tertiary-contrast);
  }

  // ! set --link-color manually for each customer to ensure a good contrast between primary & secondary-colored backgrounds where needed
  a { color: var(--link-color, unset); }

  *, *::before, *::after {
    box-sizing: border-box;
    line-height: 1.5em; // better readable multiline text 
    user-select: text;  // generally allow copy-paste
  }


  /********** top-navbar height ****************/

  // use custom variable --app-top-navbar-height for customers to set the navbar height
  app-top-navbar ion-toolbar {
    --min-height: var(--app-top-navbar-height, 56px);
  }
  #main { top: calc(var(--app-top-navbar-height, 56px) + 4px) !important; }
  
  
  /*********** popovers & modals ***************/

  
  // popover of app version in footer
  #kroko-popover {

    ion-content {
      --padding: 1em;

      
      &::part(scroll) {
        --padding-top: var(--padding);
        --padding-bottom: var(--padding);
        --padding-start: var(--padding);
        --padding-end: var(--padding);
        font-size: 1.5rem;

        display: flex;
        align-items: baseline;
        gap: .25em;
      }
    }

    img {
      height: 1.25em;
      aspect-ratio: 1;
    }

    
    // hide the ion-backdrop element
    // &::part(backdrop) { display: none; }
    &::part(content) { width: max-content; }
  }
  
  // popover on ios was too small in width. Couldn't read text content in app-collapsible-nav
  ion-popover.top-nav-collapsible.ios {
    --min-width: fit-content;
    --offset-x: -50px;
  }
  
  // ion-select popup did not display full text of the options (both android & ios)
  ion-alert {
    .alert-checkbox-label { white-space: pre-line !important; }
    .alert-tappable {
      height: initial !important;
      contain: initial !important;
    }
  }
  // ion-select popover did not display full text of the options (both android & ios)
  ion-select-popover ion-label {
    white-space: pre-line !important;
  }

  ion-select, ion-input {
    --placeholder-opacity: .6;
  }

  // alert to delete account
  #delete-account {
    .form-error-message {
        color: var(--ion-color-danger);

        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1.5em;

        ion-icon {
            flex: 16px 0 0;
            margin-right: 4px;
        }
    }
    .delete-btn[disabled] { opacity: .5; }
  }
  
  // display event filter in scrollable moldal on mobile
  .event-filter-modal .event-filter { overflow-y: auto; padding: 3em; }

  
  // alert on "insert into cart"-> select to whom:
  // let disabled appear to be checked, because they already participate in that event
  #app-add-event-to-registration-select .alert-checkbox-button-disabled {
    
    .alert-checkbox-icon {
        border-color: var(--ion-color-primary);
        background-color: var(--ion-color-primary);
    }
    
    .alert-checkbox-inner {
        left: 3px;
        top: 0;
        position: absolute;
        width: 6px;
        height: 10px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-width: 2px;
        border-top-width: 0;
        border-left-width: 0;
        border-style: solid;
        border-color: var(--ion-color-primary-contrast);
    }
  }

  
  
  ion-app.font-family-class {
    --ion-font-family: 'Montserrat';
    --ion-font-weight: light;
  }
  
  
  /*********** generic page styling ***************/
  
  #main { top: 60px; }
  
  .page-container {
    --max-width: 90%;
  
    margin: 2em auto;
    max-width: var(--max-width);

    min-height: 50vh;

    &--text-content { max-width: min(var(--max-width), 70ch); }
  }
  
  .title { text-align: center; }
  .small-font { font-size: var(--default-font-size-small); }
  
  // link color in markdown
  markdown .md-link { color: var(--md-link-color, var(--ion-text-color)); }
  app-info-banner markdown .md-link { color: var(--md-link-color, var(--ion-text-color)); }
  
  
  /*********** buttons ***************/
  
  .circular-btn {
    --diameter: 25px;
  
    width: var(--diameter);
    height: var(--diameter);
    margin: 0;
  
    --border-radius: 100%;
    --padding-start: 0;
    --padding-end: 0;
  }
  
  .delete-btn-container {
    --delete-btn-diameter: 30px;
    --delete-btn-offset: calc(var(--delete-btn-diameter) / 2);
  
    // whitespaces around
    margin: calc(1em + var(--delete-btn-offset)) calc(1em + var(--delete-btn-offset)) 1em 1em;
  
    position: relative;
  
    .delete-btn {
      @extend .circular-btn;
      --diameter: var(--delete-btn-diameter, 25px);
  
      position: absolute;
      top: calc(-1 * var(--delete-btn-offset));
      right: calc(-1 * var(--delete-btn-offset));
    }
  }

  // passwprd-eye, calendar, ... color in imput fields same color as label
  ion-item ion-button[slot=end] {
    --ion-color-primary: var(--ion-text-color, #000);
    --ion-color-primary-rgb: var(--ion-text-color-rgb, 0 0 0);
  }
  
  // navbar & sidemenu
  .logo {
    height: 53px;
    z-index: 9999;
    max-width: 175px;
  
    img { width: 100%; }
  }
  
  // use title attribute from parent, not ion-icon[name] as title
  [title] > ion-icon:not([type="button"]) { pointer-events: none; }

  // slider
  app-slider .swiper-wrapper { align-items: center; }
  
  // static pages
  .two-col-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: .3em 1em;
    width: fit-content;
  }

  // event tags such as:
  // 'Anmeldung beim Veranstalter'
  // 'Anmeldung im Moment nicht möglich'
  .registration-impossible {
    display: block;
    color: var(--color);
    background: var(--background);
    padding: var(--event-tag-padding);
    font-style: italic;
    font-weight: 800;

    &--may-change {
      @extend .registration-impossible;
      --color: var(--ion-color-warning-contrast);
      --background: var(--ion-color-warning);
    }
    &--fixed {
      @extend .registration-impossible;
      --color: var(--ion-color-danger-contrast);
      --background: var(--ion-color-danger);
    }
  }
  

  
  @media screen and (max-width: 600px) {
    .logo { height: 48px; }
  
    .slider .arrow {
      width: 50px;
      height: 50px;
    }
  
    h1 { font-size: 2rem; }
  }
}