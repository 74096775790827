// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// color generator: https://ionicframework.com/docs/theming/color-generator
// add new color: https://ionicframework.com/docs/theming/colors

/** Ionic CSS Variables **/
:root {

    /** primary **/
    --ion-color-primary: #4E794E;
    --ion-color-primary-rgb: 78,121,78;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #456a45;
    --ion-color-primary-tint: #608660;
  
    /** secondary **/
    --ion-color-secondary: #f3e958;
    --ion-color-secondary-rgb: 243,233,88;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #d6cd4d;
    --ion-color-secondary-tint: #f4eb69;
  
    /** tertiary **/
    --ion-color-tertiary: #4990a1;
    --ion-color-tertiary-rgb: 73,144,161;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #407f8e;
    --ion-color-tertiary-tint: #5b9baa;
  
    /** success **/
    --ion-color-success: #13B097;
    --ion-color-success-rgb: 19,176,151;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #119b85;
    --ion-color-success-tint: #2bb8a1;
  
    /** warning **/
    --ion-color-warning: #d18b00;
    --ion-color-warning-rgb: 209,139,0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #b87a00;
    --ion-color-warning-tint: #d6971a;
  
    /** danger **/
    --ion-color-danger: #d10000;
    --ion-color-danger-rgb: 209,0,0;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #b80000;
    --ion-color-danger-tint: #d61a1a;
  
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** contrast-primary **/
    --ion-color-contrast-primary: var(--ion-color-primary-contrast);
    --ion-color-contrast-primary-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-contrast-primary-contrast: var(--ion-color-primary);
    --ion-color-contrast-primary-contrast-rgb:  var(--ion-color-primary-rgb);
    --ion-color-contrast-primary-shade: darken(var(--ion-color-primary-contrast), 15);
    --ion-color-contrast-primary-tint: lighten(var(--ion-color-primary-contrast), 15);

    /** contrast-secondary **/
    --ion-color-contrast-secondary: var(--ion-color-secondary-contrast);
    --ion-color-contrast-secondary-rgb: var(--ion-color-secondary-contrast-rgb);
    --ion-color-contrast-secondary-contrast: var(--ion-color-secondary);
    --ion-color-contrast-secondary-contrast-rgb: var(--ion-color-secondary-rgb);
    --ion-color-contrast-secondary-shade: darken(var(--ion-color-secondary-contrast), 15);
    --ion-color-contrast-secondary-tint: lighten(var(--ion-color-secondary-contrast), 15);

    /** contrast-tertiary **/
    --ion-color-contrast-tertiary: var(--ion-color-tertiary-contrast);
    --ion-color-contrast-tertiary-rgb: var(--ion-color-tertiary-contrast-rgb);
    --ion-color-contrast-tertiary-contrast: var(--ion-color-tertiary);
    --ion-color-contrast-tertiary-contrast-rgb: var(--ion-color-tertiary-rgb);
    --ion-color-contrast-tertiary-shade: darken(var(--ion-color-tertiary-contrast), 15);
    --ion-color-contrast-tertiary-tint: lighten(var(--ion-color-tertiary-contrast), 15);
  
  
    /*
    * Dark Colors
    * -------------------------------------------
    */
    @media (prefers-color-scheme: dark) {
  
      body {
  
        /** dark **/
        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;
      
        /** medium **/
        --ion-color-medium: #92949c;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;
      
        /** light **/
        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;

      
        /** stepped colors, see https://ionicframework.com/docs/theming/themes */
        --ion-background-color: #121418;
        --ion-background-color-rgb: 18,20,24;
      
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255,255,255;
      
        --ion-color-step-50: #1e2024;
        --ion-color-step-100: #2a2c2f;
        --ion-color-step-150: #36373b;
        --ion-color-step-200: #414346;
        --ion-color-step-250: #4d4f52;
        --ion-color-step-300: #595b5d;
        --ion-color-step-350: #656669;
        --ion-color-step-400: #717274;
        --ion-color-step-450: #7d7e80;
        --ion-color-step-500: #898a8c;
        --ion-color-step-550: #949597;
        --ion-color-step-600: #a0a1a3;
        --ion-color-step-650: #acadae;
        --ion-color-step-700: #b8b9ba;
        --ion-color-step-750: #c4c4c5;
        --ion-color-step-800: #d0d0d1;
        --ion-color-step-850: #dbdcdc;
        --ion-color-step-900: #e7e8e8;
        --ion-color-step-950: #f3f3f3;
      }

      /*
      * iOS Dark Theme
      * -------------------------------------------
      */
  
      .ios body {
        --ion-toolbar-background: #0d0d0d;
        --ion-item-background: #000000;
      }
  
      /*
      * Material Design Dark Theme
      * -------------------------------------------
      */
      .md body {
        --ion-border-color: #222222;
        --ion-item-background: #1e1e1e;
        --ion-toolbar-background: #1f1f1f;
        --ion-tab-bar-background: #1f1f1f;
      }
    }








    
    // classes to all added/custom colors

    .ion-color-contrast-primary {
      --ion-color-base: var(--ion-color-contrast-primary);
      --ion-color-base-rgb: var(--ion-color-contrast-primary-rgb);
      --ion-color-contrast: var(--ion-color-contrast-primary-contrast);
      --ion-color-contrast-rgb: var(--ion-color-contrast-primary-contrast-rgb);
      --ion-color-shade: var(--ion-color-contrast-primary-shade);
      --ion-color-tint: var(--ion-color-contrast-primary-tint);
    }
    
    .ion-color-contrast-secondary {
      --ion-color-base: var(--ion-color-contrast-secondary);
      --ion-color-base-rgb: var(--ion-color-contrast-secondary-rgb);
      --ion-color-contrast: var(--ion-color-contrast-secondary-contrast);
      --ion-color-contrast-rgb: var(--ion-color-contrast-secondary-contrast-rgb);
      --ion-color-shade: var(--ion-color-contrast-secondary-shade);
      --ion-color-tint: var(--ion-color-contrast-secondary-tint);
    }
    
    .ion-color-contrast-tertiary {
      --ion-color-base: var(--ion-color-contrast-tertiary);
      --ion-color-base-rgb: var(--ion-color-contrast-tertiary-rgb);
      --ion-color-contrast: var(--ion-color-contrast-tertiary-contrast);
      --ion-color-contrast-rgb: var(--ion-color-contrast-tertiary-contrast-rgb);
      --ion-color-shade: var(--ion-color-contrast-tertiary-shade);
      --ion-color-tint: var(--ion-color-contrast-tertiary-tint);
    }
  }