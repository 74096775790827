/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 *
 * Shared Sass variables, which can be used to adjust Ionic's
 * default Sass variables, belong in "theme/(customer/)variables.scss".
 *
 * To declare rules for a specific mode, create a child rule
 * for the .md, .ios, or .wp mode classes. The mode class is
 * automatically applied to the <body> element in the app.
 *
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/pagination';
// @import 'swiper/scss/scrollbar';
// @import 'swiper/scss/zoom';
// @import '@ionic/angular/css/ionic-swiper';


/* ZI styles */

@import './theme/fonts.scss';
@import './theme/customer/fonts.scss';

@import './theme/customer/colors.scss';

@import './theme/variables.scss';
@import './theme/customer/variables.scss';

@import './theme/rules.scss';
@import './theme/customer/rules.scss';

// TODO: does this solve the spacing problem on ios?
ion-app {
    margin-top: calc(var(--ion-safe-area-top, 0px) + var(--ion-statusbar-padding, 0px));
    margin-right: var(--ion-safe-area-right, 0px);
    margin-bottom: var(--ion-safe-area-bottom, 0px);
    margin-left: var(--ion-safe-area-left, 0px);
}