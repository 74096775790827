// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // RegistrationPage status colors
  --color-status-unprocessed: #ffc305;
  --color-status-declined: #f53d3d;
  --color-status-waitinglist: #f5e100;
  --color-status-unpaid: #43a1ff;
  --color-status-external-payment: #43a1ff;
  --color-status-confirmed: #43cc00;
  --color-status-unconfirmed: #43a1ff;

  // TOP-NAVBAR
  --top-navbar-icon-spacing: 1em;

  // picto image as background
  --picto-image: url("/assets/icon/pictogram.svg");
  // --picto-color: ;

  // GENERALLY VARIABLES FOR CSS
  --mini-font-size: 10px;

  // SHOPPING CART PAGES
  --shopping-cart-element-whitespace: 10px;
  --shopping-cart-participant-background: var(--ion-color-secondary);
  --shopping-cart-participant-name-content-font-size: 16px;

  //EVENT DETAIL PAGE
  --event-content-margin: 20px;
  --event-filter-width: min(320px, 30%);

  --event-content-right-padding: 20px;
  --rounded-card-border-radius: 20px;

  // EVENTS PAGE AND PRIO PAGE
  --event-tag-padding: 0.5em;
}
